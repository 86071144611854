/* InfoWindow.css */
.info-window {
  padding: 10px;
  font-family: Arial, sans-serif;
  max-width: 250px;
}

.info-window-header {
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.vehicle-number {
  font-size: 16px;
  color: #007bff;
}

.status {
  margin-top: 4px;
}

.status-label {
  font-weight: bold;
  color: #555;
}

.status-value {
  color: #333;
}

.info-window-body {
  font-size: 14px;
}

.location {
  margin-bottom: 6px;
}

.location-label {
  font-weight: bold;
  color: #555;
}

.location-value {
  color: #333;
}

.timestamp {
  margin-bottom: 6px;
}

.timestamp-label {
  font-weight: bold;
  color: #555;
}

.timestamp-value {
  color: #333;
}
