/* css applied over complete app is written here */

* {
  margin: 0;
  padding: 0;
  max-width: 100vw;
  box-sizing: inherit;
}

*::-webkit-scrollbar {
  display: none;
}

.gm-style-iw {
  padding: 5px !important; /* Reduce internal padding */
  margin: 0 !important; /* Remove any unwanted margin */
}

.gm-style-iw-d {
  padding: 0 !important; /* Reduce padding inside the content */
}
